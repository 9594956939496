.custom-modal {
	top: 58px;
	top: calc(58px + env(safe-area-inset-top));
	bottom: 69px;
	bottom: calc(69px + env(safe-area-inset-bottom));
	--backdrop-opacity: 0;
	--width: 100%;
	--height: 100%;
	--border-radius: 0px;
	--box-shadow: none !important;

	html.app.loader-showing & {
		bottom: 0;
	}

	html.web & {
		top: 102px;
		bottom: 0;
		position: absolute;
	}
}

.contact-us-done-modal {
	@extend .custom-modal;
	bottom: 69px;
	bottom: calc(0px + env(safe-area-inset-bottom));
}

.contact-us-done-modal-web {
	@extend .custom-modal;
	top: 0px !important;
}

.faq-resize-styling {
	html.web & {
		@extend .web-modal-full-page-no-border;
	}
	html.app & {
		@extend .faq-modal;
	}
}

.drawer-modal {
	top: calc(118px + env(safe-area-inset-top));
	bottom: calc(69px + env(safe-area-inset-bottom));
	--backdrop-opacity: 0;
	--box-shadow: 0px 0px 5px 1px rgba(43, 39, 189, 0.5);
	--border-color: rgba(43, 39, 189, 1) rgba(43, 39, 189, 1)
		var(--ion-color-primary-contrast) rgba(43, 39, 189, 1);
	--border-style: solid;
	--border-width: 2px 1px 1px 1px;
	--overflow: hidden;
	contain: none;
	width: 99.2%;
	margin: auto;
	clip-path: inset(-100vh -100vw 0px -100vw);

	&::part(backdrop) {
		position: absolute;
		top: -118px;
		pointer-events: fill;
	}

	&.hydrated {
		--border-radius: 25px 25px 0px 0px;

		.ion-page {
			border-radius: 25px 25px 0px 0px;
		}
	}
	ion-header {
		background-color: transparent;
	}
	ion-content {
		background-color: transparent;
	}

	html.keyboard-open & {
		bottom: 0px;
	}

	html[dir='ltr'].web & {
		position: absolute;
		top: 0px;
		bottom: 0px;
		right: -55vw;
		--width: 44.9%;
		--height: 99.5%;
		--backdrop-opacity: 0;
		--box-shadow: 0px 0px 5px 1px rgba(43, 39, 189, 0.5);
		--border-color: rgba(43, 39, 189, 1);
		--border-style: solid;
		--border-width: 1px 1px 1px 1px;
		--overflow: hidden;
		--border-radius: 25px 0px 0px 25px;

		&::part(backdrop) {
			left: -100vw;
		}

		&.hydrated {
			border-radius: 25px 0px 0px 25px;

			.ion-page {
				border-radius: 25px 0px 0px 25px;
			}
		}
	}

	html[dir='rtl'].web & {
		position: absolute;
		top: 0px;
		bottom: 0px;
		left: -55vw;
		--width: 44.9%;
		--height: 99.5%;
		--backdrop-opacity: 0;
		--box-shadow: 0px 0px 5px 1px rgba(43, 39, 189, 0.5);
		--border-color: rgba(43, 39, 189, 1);
		--border-style: solid;
		--border-width: 1px 1px 1px 1px;
		--overflow: hidden;
		--border-radius: 0px 25px 25px 0px;

		&::part(backdrop) {
			right: -100vw;
		}

		&.hydrated {
			border-radius: 0px 25px 25px 0px;

			.ion-page {
				border-radius: 0px 25px 25px 0px;
			}
		}
	}
	html.app & {
		--width: 100%;
		--height: 100%;
	}
}

.ignoreDirectionModal {
	@extend .drawer-modal;
	bottom: 0px;

	.ion-page {
		bottom: env(safe-area-inset-bottom);
	}

	html[dir='ltr'].web & {
		position: absolute;
		top: 0px;
		bottom: 0px;
		right: -55vw;
		--width: 44.9%;
		--height: 99.5%;
		--backdrop-opacity: 0;
		--box-shadow: 0px 0px 5px 1px rgba(43, 39, 189, 0.5);
		--border-color: rgba(43, 39, 189, 1);
		--border-style: solid;
		--border-width: 1px 1px 1px 1px;
		--overflow: hidden;
		--border-radius: 25px 0px 0px 25px;

		&::part(backdrop) {
			left: -100vw;
		}

		&.hydrated {
			border-radius: 25px 0px 0px 25px;

			.ion-page {
				border-radius: 25px 0px 0px 25px;
			}
		}
	}
	html[dir='rtl'].web & {
		position: absolute;
		top: 0px;
		bottom: 0px;
		right: -110vw;
		--width: 44.9%;
		--height: 99.5%;
		--backdrop-opacity: 0;
		--box-shadow: 0px 0px 5px 1px rgba(43, 39, 189, 0.5);
		--border-color: rgba(43, 39, 189, 1);
		--border-style: solid;
		--border-width: 1px 1px 1px 1px;
		--overflow: hidden;
		--border-radius: 25px 0px 0px 25px;

		&::part(backdrop) {
			left: -100vw;
		}

		&.hydrated {
			border-radius: 25px 0px 0px 25px;

			.ion-page {
				border-radius: 25px 0px 0px 25px;
			}
		}
	}
}

.drawer-modal-from-bottom {
	@extend .drawer-modal;
	bottom: 0px;
}

.web-modal-full-page {
	top: 100px;
	--height: 100%;
	--width: 100%;
}

.passwordChangedModal {
	top: 0px;
	--height: 100%;
	--width: 100%;
	--border-radius: 0px 0px 0px 0px;

	&.hydrated {
		border-radius: 0px 0px 0px 0px;

		.ion-page {
			border-radius: 0px 0px 0px 0px;
		}
	}
}

.faq-modal {
	top: calc(60px + env(safe-area-inset-top));
	bottom: calc(69px + env(safe-area-inset-bottom));
	--backdrop-opacity: 0;
	contain: none;
	width: 100%;
	margin: auto;
	clip-path: inset(-15px -15px 0px -15px);
	html.keyboard-open & {
		bottom: 0px;
		--border-color: rgba(28, 134, 238, 0.5);
	}
	html.app & {
		--width: 100%;
		--height: 100%;
		--border-radius: 0px;
	}
}

.web-modal-full-page-no-border {
	top: 5%;
	--height: 100%;
	--width: 100%;
	border-radius: 0px;
	--border-radius: 0px;
	--box-shadow: none !important;
}

.drawer-keyboard-aware {
	html.keyboard-open & {
		bottom: var(--keyboard-height);
	}
}

.crossIcon:hover{
	cursor:pointer ;
}