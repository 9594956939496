@media screen and (orientation: portrait) {
	html {
		--layout: app;
		--colSizeGrow: 6;
		--colSizeSessions: 12;
		--colSizeInterest: 6;
		--colSizeServices: 6;
		--stackFontSize: 4.4vw;
		--thumbnailFontSize: clamp(
			14px,
			calc((var(--colSizeGrow) - 3.5) * 1vw),
			15.38px
		);
		--iconsSearch: clamp(
			14px,
			calc((var(--colSizeGrow) - 3.5) * 1vw),
			16px
		);

		.web {
			display: none;
		}
	}
}

@media screen and (orientation: landscape) {
	html {
		--layout: web;
		--colSizeGrow: 3;
		--checkInWidth: 120%;
		--checkInMargin: 40%;
		--colSizeServices: 4;
		--colSizeSessions: 4;
		--colSizeInterest: 3;
		--emojiWidth: 6vw;
		--stackFontSize: 2.2vw;
		--thumbnailFontSize: calc((var(--colSizeGrow) - 1) * 1vw);
		--iconsSearch: calc((var(--colSizeGrow) - 1.6) * 1vw);
		--thumbnailFontSizeSearch: clamp(
			15.38px,
			calc((var(--colSizeGrow) - 1) * 1vw),
			21.98px
		);
		--thumbnailFontSizeMore: calc((var(--colSizeGrow) - 1.5) * 1vw);
		.app {
			display: none;
		}
	}
	@media screen and(max-width: 821px) {
		html {
			--layout: app;
			--colSizeGrow: 6;
			--colSizeInterest: 6;
			--colSizeServices: 6;
			--colSizeSessions: 12;
			--stackFontSize: 4.4vw;
			--thumbnailFontSize: clamp(
				14px,
				calc((var(--colSizeGrow) - 3.5) * 1vw),
				15.38px
			);
			--iconsSearch: clamp(
				14px,
				calc((var(--colSizeGrow) - 3.5) * 1vw),
				15.38px
			);

			.web {
				display: none;
			}
		}
	}
}

@media screen and (min-width: 824px) {
	html {
		--colSizeServices: 3;
		--colSizeSessions: 6;
	}
}

@media screen and (min-width: 950px) {
	html {
		--colSizeSessions: 4;
	}
}

@media screen and (min-width: 1200px) {
	html {
		--colSizeGrow: 2;
		--colSizeSessions: 3;
		--checkInWidth: 130%;
		--checkInMargin: 85%;
		--emojiWidth: 4vw;
		--stackFontSize: 1.5vw;
		--thumbnailFontSize: max(calc((var(--colSizeGrow) - 1) * 1vw), 23.98px);
		--iconsSearch: max(calc((var(--colSizeGrow) - 1) * 1vw), 16.98px);
		--thumbnailFontSizeSearch: max(
			calc((var(--colSizeGrow) - 1) * 1vw),
			21.98px
		);
		--thumbnailFontSizeMore: max(
			calc((var(--colSizeGrow) - 1) * 1vw),
			17.985px
		);
	}
}

@media screen and (min-width: 1700px) {
	html {
		--colSizeSessions: 2;
	}
}
