.md-card {
	position: relative;
	//	background-color: #fff;
	background-color: var(--ion-color-secondary);

	//margin-bottom: 20px;
	border-radius: var(--ion-border-radius);

	.md-card-heading {
		padding: 7px 8px;
		> .md-card-title {
			margin: 0;
			font-size: 18px;
			color: white;
		}
		> .md-card-icon {
			float: right;
			color: rgba(255, 255, 255, 0.87);
			font-size: 20px;
		}
	}
	.md-card-item {
		position: relative;
		min-height: 120px;
		> p {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba(0, 0, 0, 0.15);
			margin: 0;
		}
	}
	.md-card-offset {
		position: relative;
		z-index: 1;
		> * {
			position: absolute;
			top: 0;
			right: 15px;
			transform: translate(0, -50%);
		}
	}

	.md-card-toolbar {
		position: relative;
		width: 100%;
		min-height: 64px;
		font-size: 18px;
		line-height: 64px;
		padding-left: 22px;
		z-index: 2;
	}
	.md-card-subheader {
		padding: 16px 0 16px 16px;
		line-height: 0.75em;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0.01em;
		color: rgba(0, 0, 0, 0.54);
		// Adjustmen for list next to subheader
	}

	.md-card-footer {
		padding: 10px 10px;
	}

	.md-card-divider {
		display: block;
		margin-top: 10px;
		margin-bottom: 10px;
		border: 1px solid rgba(0, 0, 0, 0.12);
	}

	&.md-card-map {
		min-height: 280px;
		.md-card-footer {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			border: 0;
			background-color: transparent;
		}
	}
}

.md-card-dialog {
	position: relative;
	background-color: var(--ion-color-primary-contrast);

	margin-bottom: 20px;
	//border-radius: var(--ion-border-radius);

	.md-card-heading-dialog {
		padding: 10px 11px;
		background-color: var(--ion-color-primary);
		> .md-card-title-dialog {
			margin: 0;
			font-size: 18px;
			color: white;
		}
		> .md-card-icon {
			float: right;
			color: rgba(255, 255, 255, 0.87);
			font-size: 20px;
		}
	}
	.md-card-item {
		position: relative;
		min-height: 120px;
		> p {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba(0, 0, 0, 0.15);
			margin: 0;
		}
	}
	.md-card-offset {
		position: relative;
		z-index: 1;
		> * {
			position: absolute;
			top: 0;
			right: 15px;
			transform: translate(0, -50%);
		}
	}

	.md-card-toolbar {
		position: relative;
		width: 100%;
		min-height: 64px;
		font-size: 18px;
		line-height: 64px;
		padding-left: 22px;
		z-index: 2;
	}
	.md-card-subheader {
		padding: 16px 0 16px 16px;
		line-height: 0.75em;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0.01em;
		color: rgba(0, 0, 0, 0.54);
		// Adjustmen for list next to subheader
	}

	.md-card-footer {
		padding: 10px 22px;
	}

	.md-card-divider {
		display: block;
		margin-top: 10px;
		margin-bottom: 10px;
		border: 1px solid rgba(0, 0, 0, 0.12);
	}

	&.md-card-map {
		min-height: 280px;
		.md-card-footer {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			border: 0;
			background-color: transparent;
		}
	}
}
