@import 'utils';
a {
	text-decoration: none;
}

.native-input[type='date']::-webkit-calendar-picker-indicator {
	filter: invert(1);
}

mark {
	background-color: transparent;
	color: var(--ion-color-dark);
}

body {
	--ion-tab-bar-color: var(--ion-color-light);
	--ion-toolbar-color: var(--ion-color-primary);
	--ion-background-color: var(--ion-color-primary-contrast);
	--ion-text-color: var(--ion-color-dark);
	--ion-text-color-rgb: var(--ion-color-dark-rgb);
	--ion-toolbar-background: var(--ion-color-primary-contrast);
	--ion-color-step-600: var(--ion-color-primary);

	//The changes below force the screen to act as mobile if window size is larger than mobile
	@media only screen and (min-width: 600px) {
		background-color: transparent;
	}

	hyphens: none;
}

.test {
	#test-mode-container {
		visibility: visible !important;
	}
}

em.search-highlight {
	font-style: normal;
	font-weight: bold;
	color: var(--ion-color-primary);
}

//Ionic components styles
ion-text {
	font-size: var(--ion-small-font-size);
}

ion-label {
	font-size: var(--ion-extra-small-font-size);
}

ion-select {
	--placeholder-color: var(--ion-color-medium);
	--placeholder-opacity: 1;
	--color: var(--ion-color-medium);
	color: var(--ion-color-medium);

	&::part(icon) {
		opacity: 1;
		color: var(--ion-color-medium);
	}
}

ion-card {
	--background: var(--ion-color-primary);
	--color: white;
	height: auto;
}

html.app :host {
	ion-card {
		--background: #045aa7;
		--color: #9efff0;
		height: auto;
	}
}

ion-card-title {
	--color: white;
}

ion-breadcrumb {
	--color: lightgray;
}

ion-header {
	background-color: var(--ion-color-primary-contrast);

	&.header-md::after {
		background-image: none;
	}

	ion-title {
		text-align: center;
		font-weight: bold;
		font-size: var(--ion-medium-font-size);
		text-transform: capitalize;
	}
}

.ion-activatable {
	pointer-events: auto;
}

ion-col {
	padding: 6px;
}

ion-footer {
	background-color: var(--ion-color-primary-contrast);
}

ion-content {
	--offset-bottom: auto !important;
	--overflow: hidden;
	overflow: auto;

	&::-webkit-scrollbar {
		display: none;
	}
	background-color: var(--ion-color-primary-contrast);
}

ion-button {
	text-transform: initial;
	min-height: 30px;
	--border-radius: var(--ion-border-radius);
	font-size: var(--ion-small-font-size);
	letter-spacing: 1px;
}

ion-button:focus {
	outline: none;
}

ion-slide {
	width: auto;
}

swiper-slide {
	width: auto;
}

input {
	&:focus {
		outline: none;
	}
}

ion-button > div {
	width: 100%;
	text-align: center;
	margin-right: 20px;
}
ion-button.button-clear.ion-activated {
	opacity: 1;
}

ion-icon {
	//This should remain static and not change per variable
	font-size: 32px;
}

ion-tab-bar {
	height: 69px;
	box-shadow: 0px -16px 16px -19px rgba(0, 0, 0, 0.15);
	--border: 1px solid var(--ion-color-lighter);
}

ion-breadcrumb {
	//--color: rgb(81, 155, 198);
	--color-active: white;
	//--color-hover: rgb(103, 61, 180);
}

mat-form-field {
	width: 100%;
	height: 100%;
}

ion-header.welcome,
ion-header.contactus,
ion-header.feedback {
	&.tertiary {
		ion-toolbar {
			--background: var(--ion-color-tertiary);
		}
	}

	ion-toolbar {
		--color: var(--ion-color-primary);

		ion-button {
			font-size: 12pt;
			font-weight: bold;

			ion-icon {
				font-size: 18pt;
			}
		}
	}
}

ion-header.drawer-modal-header-template {
	height: 60px;
	color: var(--ion-color-primary);
	border-bottom: solid 1px #00000033;
	position: relative;

	.crossIcon {
		color: #575757;
		height: 20px;
		width: 20px;
		float: left;
		position: absolute;
		top: 50%;
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);

		html[dir='rtl'] & {
			margin: 0px 20px 0px 0px;
		}

		html[dir='ltr'] & {
			margin: 0px 0px 0px 20px;
		}
	}

	.title-holder {
		font-size: 18px;
		font-weight: 500;
		text-align: center;
		max-width: max-content;
		width: 75%;
		height: auto;
		margin: auto;
		position: absolute;
		top: 50%;
		left: 50%;
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		p {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			html[dir='rtl'] & {
				margin: 0px 20px 0px 0px;
			}

			html[dir='ltr'] & {
				margin: 0px 0px 0px 20px;
			}
		}
	}
}

ion-searchbar {
	.sc-ion-searchbar-md-h,
	.searchbar-input.sc-ion-searchbar-md,
	.searchbar-input.sc-ion-searchbar-ios {
		--border-radius: 30px;
		--box-shadow: 0px 0px 0px 1px var(--ion-color-medium);
		--placeholder-color: var(--ion-color-medium);
		--placeholder-opacity: 1;
		padding: 9px 10px 9px 10px;
		color: #646464;
	}

	.searchbar-search-icon.sc-ion-searchbar-md {
		--icon-color: var(--ion-color-medium);
		position: absolute;
		top: 13px;
		left: 90%;
	}

	:focus-within,
	&.searchingActive {
		.searchbar-search-icon.sc-ion-searchbar-md.hydrated {
			position: fixed;
			display: none;
		}

		.searchbar-input.sc-ion-searchbar-md {
			padding-inline-start: 10px;
		}

		.sc-ion-searchbar-md-h,
		.searchbar-input.sc-ion-searchbar-md,
		.searchbar-input.sc-ion-searchbar-ios {
			--box-shadow: 0px 0px 0px 1px var(--ion-color-dark);
			color: var(--ion-color-primary);
		}
	}
	:empty {
		.searchbar-search-icon.sc-ion-searchbar-md {
			position: fixed;
			display: block;
		}
	}

	.searchbar-input-container.sc-ion-searchbar-md {
		.searchbar-clear-icon.sc-ion-searchbar-md {
			color: var(--ion-color-lighter);
			background-color: #646464;
			border-radius: 50%;
			height: 45%;
		}
	}
}

ion-segment {
	min-height: auto;
	background-color: transparent;
	padding: 0px 19px 0px 19px;
	min-width: 100%;
}

ion-segment-button {
	--background-checked: #fefefe;
	--indicator-height: 100%;
	--indicator-color: var(--ion-color-tertiary);
	--indicator-box-shadow: none;
	--border-radius: 100px;
	border: solid 1px #ebebeb;
	margin: 2px;

	&::before {
		border-left: 0px;
		border-right: 0px;
	}

	&::part(indicator) {
		padding: 0px;
	}

	ion-label {
		color: black;
	}
}

.ion-page {
	background-color: var(--ion-color-primary-contrast);
}

.searchBarContainer {
	padding: 12px;
}

.text-center {
	text-align: center;
}

.clickable {
	cursor: pointer;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.pager-hide {
	.swiper-pagination {
		display: none !important;
	}
}

.paper-stack {
	position: relative;
	//-webkit-transform: translate3d(0, 0, 0);

	&,
	&::before,
	&::after {
		box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.123);
		border-radius: 2px;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		height: 95%;
		width: 100%;
		background-color: var(--ion-color-dark);
	}

	&::after {
		top: -2px;
		right: -2px;
		transform: rotate(1.4deg);
		z-index: 0;
		opacity: 0.2;
		html.web & {
			opacity: 0.1;
		}
	}

	&::before {
		right: 1px;
		top: 2px;
		transform: rotate(-2.4deg);
		z-index: 0;
		opacity: 0.5;
		html.web & {
			opacity: 0.3;
		}
	}
}

.hide {
	display: none;
}

.container-box {
	z-index: 1;
	hyphens: auto;
	background-color: var(--ion-color-light-tint);
	color: var(--ion-color-primary-contrast);
	width: 100%;
	padding-top: 100%; /* 1:1 Aspect Ratio */
	position: relative; /* If you want text inside of it */
	border-radius: var(--ion-border-radius);
	background-size: cover;
	background-position: center;
	cursor: pointer;

	.content {
		text-align: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		right: 0;
		padding: 0px 8px;

		.name {
			font-size: var(--stackFontSize);
			hyphens: manual;
			word-break: keep-all;
			font-weight: 500;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
		}
	}

	&.half {
		padding-top: 50%;
	}

	&.three-quarters {
		padding-top: 75%;
	}

	&.web {
		margin-bottom: 20px;
	}
}

.app-loading {
	--background: transparent;
	transform: scale(3);
}

/* Deals with the chat bot from ZOHO */
.eap-body {
	.zsiq_floatmain,
	.zsiq_float1 {
		opacity: 0 !important;
		z-index: -1 !important;
	}

	.zls-sptwndw {
		bottom: 0px !important;
		z-index: -1 !important;
		max-height: calc(
			100% - 58px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
		);
	}

	.zls-sptwndw.showing {
		z-index: 100 !important;
		html.web[dir='ltr'] & {
			right: 8px;
			left: auto;
		}
		html.web[dir='rtl'] & {
			left: 10px;
			right: auto;
		}
	}

	@media only screen and (min-width: 100px) and (max-width: 555px) {
		.siqembed[embedtheme],
		.zls-sptwndw {
			bottom: 0px !important;
		}
	}

	.z-cb {
		color: #fff;
		position: fixed;
		top: 19px;
		right: 19px;
		z-index: 200;
		img {
			width: 16px;
			height: 16px;
		}
	}
}

ion-modal.modal-default:not(.overlay-hidden) ~ ion-modal.modal-default {
	--backdrop-opacity: 0;
	clip-path: inset(-100vh -100vw 0px -100vw);
	--box-shadow: 0px 0px 5px 1px rgba(43, 39, 189, 0.5);
}

[dir='rtl'].sc-ion-searchbar-md .searchbar-search-icon.sc-ion-searchbar-md,
[dir='rtl'].sc-ion-searchbar-md-h .searchbar-search-icon.sc-ion-searchbar-md,
[dir='rtl'] .sc-ion-searchbar-md-h .searchbar-search-icon.sc-ion-searchbar-md {
	left: 4%;
	right: unset;
}

ion-accordion-group {
	ion-accordion {
		.ion-accordion-toggle-icon {
			html.web & {
				color: var(--ion-color-primary);
			}
			font-size: 20px;
			color: #646464;
		}
	}
}

ion-accordion.accordion-expanding > [slot='header'] .ion-accordion-toggle-icon,
ion-accordion.accordion-expanded > [slot='header'] .ion-accordion-toggle-icon {
	html[dir='ltr'] & {
		transform: rotate(90deg);
	}
	html[dir='rtl'] & {
		transform: rotate(-90deg);
	}
}

ion-accordion.accordionFAQ.accordion-expanding
	> [slot='header']
	.ion-accordion-toggle-icon,
ion-accordion.accordionFAQ.accordion-expanded
	> [slot='header']
	.ion-accordion-toggle-icon {
	html.web[dir='ltr'] & {
		transform: rotate(0deg) !important;
	}
	html.web[dir='rtl'] & {
		transform: rotate(0deg) !important;
	}
	html.app[dir='ltr'] & {
		color: white !important;
		transform: rotate(90deg) !important;
	}
	html.app[dir='rtl'] & {
		color: white !important;
		transform: rotate(-90deg) !important;
	}
}

ion-accordion.accordionFAQ.accordion-expanding,
ion-accordion.accordionFAQ.accordion-expanded {
	.header {
		div {
			.search-highlight {
				html.app & {
					color: white !important;
				}
			}
		}
	}
}

.hover-text::-webkit-scrollbar {
	display: none;
}

.hover-text {
	position: fixed;
	border-radius: 10px;
	padding: 10px;
	overflow-y: scroll;
	background-color: white;
	-ms-overflow-style: none;
	scrollbar-width: none;
	text-align: center;
	min-width: 100px;
}

.defaultFont {
	font-family: var(--ion-default-font-family) !important;
}

.contentLang {
	font-family: var(--ion-content-font-family) !important;
}

.greyed-color {
	color: #646464;
}

.blue-color {
	color: #2b27bd;
}

.resizable {
	height: 100%;
	width: 100%;
}

.bg-primary {
	background-color: var(--ion-color-primary);
}

.bg-secondary {
	background-color: var(--ion-color-secondary);
}

.rounded {
	border-radius: var(--ion-border-radius);
}

.text-right {
	text-align: right;
}

ion-card-subtitle {
	padding-bottom: 5px;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
	outline-style: solid;
	outline-color: #efefed;
	outline-width: 1px;
	color: var(--ion-color-medium);
	border-radius: var(--border-radius);
	border: 0;
	background-color: white;
}

.mat-form-field-flex:hover {
	outline-color: black;
}

.mat-form-field-underline {
	width: 0 !important;
}

//Custom dialog sccs
.custom-dialog-container .mat-dialog-container {
	padding: 0px;
	height: fit-content;
	overflow-y: hidden;
}

.custom-dialog-container .md-card {
	margin-bottom: 2px;
}

.custom-dialog-container-find .mat-dialog-container {
	overflow: scroll !important;
}

ion-toast.toast-success {
	--background: #113040;
	--box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
	--color: white;
}

ion-toast.toast-error {
	--background: red;
	--box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
	--color: white;
}

html[dir='rtl'] {
	.float-right {
		float: left !important;
	}

	.float-left {
		float: right !important;
	}

	.btn-group {
		direction: ltr;
	}

	.md-card-heading {
		text-align: right;
	}
}

html[dir='ltr'] {
	.md-card-heading {
		text-align: left;
	}

	.float-right {
		float: right;
	}

	.float-left {
		float: left;
	}
}

mat-stepper {
	background-color: var(--ion-color-primary) !important;
	pointer-events: none;

	.mat-stepper-horizontal-line {
		background: var(--ion-color-primary-contrast);
	}
	.mat-step-icon-selected,
	.mat-step-icon-state-done {
		background-color: rgba(0, 0, 0, 0.54) !important;
	}
}

.content-card {
	padding: 0px 3px 3px !important;
}

.content-card-header {
	padding-bottom: 0px !important;
}
