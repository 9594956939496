@font-face {
    font-family: ApaxThree;
    src: url("/assets/fonts/ApaxThree-Thin.otf");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: ApaxThree;
    src: url("/assets/fonts/ApaxThree-ThinItalic.otf");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: ApaxThree;
    src: url("/assets/fonts/ApaxThree-Light.otf");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: ApaxThree;
    src: url("/assets/fonts/ApaxThree-LightItalic.otf");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: ApaxThree;
    src: url("/assets/fonts/ApaxThree-Regular.otf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: ApaxThree;
    src: url("/assets/fonts/ApaxThree-RegularItalic.otf");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: ApaxThree;
    src: url("/assets/fonts/ApaxThree-Medium.otf");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: ApaxThree;
    src: url("/assets/fonts/ApaxThree-MediumItalic.otf");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: ApaxThree;
    src: url("/assets/fonts/ApaxThree-Bold.otf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: ApaxThree;
    src: url("/assets/fonts/ApaxThree-BoldItalic.otf");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: ApaxThree;
    src: url("/assets/fonts/ApaxThree-Superbold.otf");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: ApaxThree;
    src: url("/assets/fonts/ApaxThree-SuperboldItalic.otf");
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}