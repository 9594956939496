// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
	--ion-border-radius: 8px;
	--ion-tab-spacing: 140px;

	--ion-font-family: ApaxThree;
	--ion-default-font-family: ApaxThree;
	--ion-content-font-family: ApaxThree;

	--ion-line-height-icon-override: inherit;
	--ion-line-height-min: inherit;
	--ion-line-height-min-mid: inherit;
	--ion-line-height-mid: inherit;
	--ion-line-height-mid-max: 28.8px;
	--ion-line-height-max: inherit;
	--ion-line-height-max-override: inherit;

	--ion-font-size-intro-heading: 8.8vw;
	--ion-font-size-intro-body: 3.2vh;
	--ion-default-font-size: 15pt;
	--ion-extra-small-font-size: calc(var(--ion-default-font-size) * 0.6);
	--ion-small-font-size: calc(var(--ion-default-font-size) * 0.8);
	--ion-medium-font-size: calc(var(--ion-default-font-size) * 1);
	--ion-large-font-size: calc(var(--ion-default-font-size) * 1.2);
	--ion-extra-large-font-size: calc(var(--ion-default-font-size) * 1.4);
	--ion-extra-extra-large-font-size: calc(var(--ion-default-font-size) * 1.6);
	--ion-client-height-override: 0px;

	/** primary **/
	--ion-color-primary: #045aa7;
	--ion-color-primary-rgb: 43, 39, 189;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #3171e0;
	--ion-color-primary-tint: #4c8dff;

	/** secondary **/
	--ion-color-secondary: #013266;
	--ion-color-secondary-rgb: 61, 194, 255;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #36abe0;
	--ion-color-secondary-tint: #50c8ff;

	/** tertiary **/
	--ion-color-tertiary: #00abf5;
	--ion-color-tertiary-rgb: 210, 247, 244;
	--ion-color-tertiary-contrast: #2b27bd;
	--ion-color-tertiary-contrast-rgb: 56, 128, 255;
	--ion-color-tertiary-shade: #b9d9d7;
	--ion-color-tertiary-tint: #d7f8f5;
	--ion-color-tertiary-fifty: #d2f7f480;

	/** success **/
	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	/** warning **/
	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	/** danger **/
	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	/** dark **/
	--ion-color-dark: #646464;
	--ion-color-dark-rgb: 34, 36, 40;
	--ion-color-dark-contrast: #b5b5b5;
	--ion-color-dark-contrast-rgb: 181, 181, 181;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	/** alpha  values **/
	--ion-color-dark-alpha: #64646480;

	/** medium **/
	--ion-color-medium: #646464;
	--ion-color-medium-rgb: 100, 100, 100;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #585858;
	--ion-color-medium-tint: #747474;

	/** light **/
	--ion-color-light: #b5b5b5;
	--ion-color-light-rgb: 181, 181, 181;
	--ion-color-light-contrast: #646464;
	--ion-color-light-contrast-rgb: 34, 36, 40;
	--ion-color-light-shade: #9f9f9f;
	--ion-color-light-tint: #bcbcbc;

	/** lighter **/
	--ion-color-lighter: #f4f5f8;
	--ion-color-lighter-rgb: 244, 245, 248;
	--ion-color-lighter-contrast: #646464;
	--ion-color-lighter-contrast-rgb: 34, 36, 40;
	--ion-color-lighter-shade: #d7d8da;
	--ion-color-lighter-tint: #f5f6f9;

	/** lightest **/
	--ion-color-lighter: #efefef;

	.dark {
		/** primary **/
		--ion-color-primary: #e7e9ff;
		--ion-color-primary-rgb: 231, 233, 255;
		--ion-color-primary-contrast: #646464;
		--ion-color-primary-contrast-rgb: 34, 36, 40;
		--ion-color-primary-shade: #cbcde0;
		--ion-color-primary-tint: #e9ebff;

		/** secondary **/
		--ion-color-secondary: #3dc2ff;
		--ion-color-secondary-rgb: 61, 194, 255;
		--ion-color-secondary-contrast: #ffffff;
		--ion-color-secondary-contrast-rgb: 255, 255, 255;
		--ion-color-secondary-shade: #36abe0;
		--ion-color-secondary-tint: #50c8ff;

		/** tertiary **/
		--ion-color-tertiary: #5260ff;
		--ion-color-tertiary-rgb: 82, 96, 255;
		--ion-color-tertiary-contrast: #ffffff;
		--ion-color-tertiary-contrast-rgb: 255, 255, 255;
		--ion-color-tertiary-shade: #4854e0;
		--ion-color-tertiary-tint: #6370ff;

		/** success **/
		--ion-color-success: #2dd36f;
		--ion-color-success-rgb: 45, 211, 111;
		--ion-color-success-contrast: #ffffff;
		--ion-color-success-contrast-rgb: 255, 255, 255;
		--ion-color-success-shade: #28ba62;
		--ion-color-success-tint: #42d77d;

		/** warning **/
		--ion-color-warning: #ffc409;
		--ion-color-warning-rgb: 255, 196, 9;
		--ion-color-warning-contrast: #000000;
		--ion-color-warning-contrast-rgb: 0, 0, 0;
		--ion-color-warning-shade: #e0ac08;
		--ion-color-warning-tint: #ffca22;

		/** danger **/
		--ion-color-danger: #eb445a;
		--ion-color-danger-rgb: 235, 68, 90;
		--ion-color-danger-contrast: #ffffff;
		--ion-color-danger-contrast-rgb: 255, 255, 255;
		--ion-color-danger-shade: #cf3c4f;
		--ion-color-danger-tint: #ed576b;

		/** dark **/
		--ion-color-dark: #b5b5b5;
		--ion-color-dark-rgb: 181, 181, 181;
		--ion-color-dark-contrast: #646464;
		--ion-color-dark-contrast-rgb: 34, 36, 40;
		--ion-color-dark-shade: #9f9f9f;
		--ion-color-dark-tint: #bcbcbc;

		/** medium **/
		--ion-color-medium: #646464;
		--ion-color-medium-rgb: 100, 100, 100;
		--ion-color-medium-contrast: #ffffff;
		--ion-color-medium-contrast-rgb: 255, 255, 255;
		--ion-color-medium-shade: #585858;
		--ion-color-medium-tint: #747474;

		/** light **/
		--ion-color-light: #bcbcbc;
		--ion-color-light-rgb: 188, 188, 188;
		--ion-color-light-contrast: #646464;
		--ion-color-light-contrast-rgb: 100, 100, 100;
		--ion-color-light-shade: #a5a5a5;
		--ion-color-light-tint: #c3c3c3;

		/** lighter **/
		--ion-color-lighter: #f4f5f8;
		--ion-color-lighter-rgb: 244, 245, 248;
		--ion-color-lighter-contrast: #646464;
		--ion-color-lighter-contrast-rgb: 34, 36, 40;
		--ion-color-lighter-shade: #d7d8da;
		--ion-color-lighter-tint: #f5f6f9;
	}
}
